export function getMatchingBrowserCulture(supportedCultures: string[]): string | null {
    const cultures = navigator.languages.map(lang => String(lang));
    for (let i = 0; i < supportedCultures.length; i++) {
        const supportedCulture = supportedCultures[i];
        for (let j = 0; j < cultures.length; j++) {
            const culture = cultures[j];
            if (supportedCulture.toUpperCase() === culture.toUpperCase()) {
                return supportedCulture;
            }
            const split = culture.split('-');
            const region = split.length > 1 ? split[split.length - 1] : split[0];
            const supportedSplit = supportedCulture.split('-');
            const supportedRegion = supportedSplit.length > 1 ? supportedSplit[supportedSplit.length - 1] : supportedSplit[0];
            if (region.toUpperCase() === supportedRegion.toUpperCase()) {
                return supportedCulture;
            }
        }
    }
    return null;
}
