import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layouts/layout/layout.component';
import { AppCustomPreloader } from './core/automation';


const routes: Routes = [
    {
        path: 'management',
        data: { preload: false },
        loadChildren: () => import('@gallagher/areas/management/management.module').then(m => m.ManagementModule)
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                data: { preload: true },
                loadChildren: () => import('@gallagher/areas/main/main.module').then(m => m.MainModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader })],
    exports: [RouterModule],
    providers: [AppCustomPreloader]
})
export class AppRoutingModule { }
