export class RotatingIterator<T> {

    private index: number;

    constructor(private readonly array: T[], startIndex = 0) {
        if (array.length === 0) {
            throw new Error('empty arrays are not allowed');
        }
        this.index = startIndex;
    }

    next(step = 1): T {

        if (step === 0) {
            return this.array[this.index];
        }

        if (step > 0) {
            for (let i = 0; i < step; i++) {
                this.index++;
                if (this.index === this.array.length) {
                    this.index = 0;
                }
            }
        }
        else {
            for (let i = 0; i < Math.abs(step); i++) {
                this.index--;
                if (this.index < 0) {
                    this.index = this.array.length - 1;
                }
            }
        }

        return this.array[this.index];
    }
}
