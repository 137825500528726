/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import { appConfig } from '../configuration/app.config';
import { StorageUnit } from './models/storage-unit';
import { StorageFactoryService } from './storage-factory.service';

const tokenClockSkew = 3000; // milliseconds


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly storageUnit: StorageUnit;
    private refreshTokenSubscription: Subscription | null = null;


    constructor(
        storageFactoryService: StorageFactoryService,
        private readonly http: HttpClient) {
        this.storageUnit = storageFactoryService.createStorage('auth', 'session');
        this.storageUnit.monitorItem('token').subscribe(item => {
            const token = this.parseToken(item.newValue);
            if (token) {
                const expires_in = token.expires_in * 1000;
                const exp = Date.now() + expires_in - tokenClockSkew;
                this.storageUnit.setItem('token_expires_on', exp.toString());
                this.initializeAutoRefresh(token);
            }
        });
    }


    login(username: string, password: string, client_id: string): Observable<boolean> {
        const url = `${appConfig.apiUrl}/auth/openid-connect/token`;
        const form = new FormData();
        form.set('grant_type', 'password');
        form.set('client_id', client_id);
        form.set('username', username);
        form.set('password', password);
        return this.http.post<Token>(url, form).pipe(catchError(err => this.handleLoginError(err)), map(token => {
            this.stopAutoRefresh();
            if (token?.access_token) {
                this.storageUnit.setItem('token', JSON.stringify(token));
                return true;
            }
            return false;
        }));
    }

    isLoggedIn() {
        return this.storageUnit.getItem('token_expires_on').pipe(map(exp => {
            if (exp == null) {
                return false;
            }
            const ms = parseInt(exp);
            if (isNaN(ms)) {
                return false;
            }
            const isInTheFuture = ms > Date.now();
            return isInTheFuture;
        }));
    }

    logout(): Observable<void> {
        this.stopAutoRefresh();
        return this.getToken().pipe(mergeMap(token => {
            if (!token) {
                return of(void 0);
            }
            this.storageUnit.removeItem('token');
            return this.revokeRefreshToken(token.refresh_token);
        }));
    }

    refreshToken(refresh_token: string): Observable<boolean> {
        const url = `${appConfig.apiUrl}/auth/openid-connect/token`;
        const form = new FormData();
        form.set('grant_type', 'refresh_token');
        form.set('refresh_token', refresh_token);
        return this.http.post<Token>(url, form).pipe(map(token => {
            this.storageUnit.setItem('token', JSON.stringify(token));
            return !!token?.access_token;
        }), catchError(() => of(false)));
    }

    updateLoginState(): Observable<boolean> {
        return this.getToken().pipe(mergeMap(token => {
            if (!token) {
                return of(false);
            }
            return this.refreshToken(token.refresh_token);
        }));
    }

    getToken(): Observable<Token | null> {
        return this.storageUnit.getItem('token').pipe(map(json => this.parseToken(json)));
    }


    private revokeRefreshToken(refreshToken: string) {
        const url = `${appConfig.apiUrl}/auth/openid-connect/revoke`;
        const form = new FormData();
        form.set('token_type_hint', 'refresh_token');
        form.set('token', refreshToken);
        return this.http.post<void>(url, form).pipe(catchError(() => of(void 0)));
    }

    private handleLoginError(error: unknown): Observable<null> {
        if (error instanceof HttpErrorResponse && error.status >= 400 && error.status < 500) {
            return of(null);
        }
        return throwError(error);
    }

    private stopAutoRefresh(): void {
        if (this.refreshTokenSubscription) {
            this.refreshTokenSubscription.unsubscribe();
            this.refreshTokenSubscription = null;
        }
    }

    private initializeAutoRefresh(token: Token): void {
        this.stopAutoRefresh();
        if (token.refresh_token && !isNaN(token.expires_in)) {
            const timeToDelay = (token.expires_in * 1000) - tokenClockSkew;
            this.refreshTokenSubscription = of('complete').pipe(delay(timeToDelay)).subscribe(() => this.updateLoginState().subscribe(isLoggedIn => {
                if (!isLoggedIn) {
                    this.stopAutoRefresh();
                }
            }));
        }
    }

    private parseToken(token: string | null): Token | null {
        if (!token) {
            return null;
        }
        try {
            const obj = JSON.parse(token) as Token;
            if (obj.access_token && !isNaN(obj.expires_in)) {
                return obj;
            }
        }
        catch (error) {
            // Parsing failed
        }
        return null;
    }
}

interface Token {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
}
