import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { StorageItemChange } from './models/storage-item-change';
import { StorageUnit } from './models/storage-unit';

export type StorageType = 'memory' | 'persistent' | 'session';

@Injectable({
    providedIn: 'root'
})
export class StorageFactoryService implements OnDestroy {

    private readonly storageCollection: Record<StorageType, Record<string, StorageUnitImpl>> = {
        memory: {},
        persistent: {},
        session: {}
    };
    private readonly destroyed$ = new Subject<boolean>();


    constructor() { }


    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    createStorage(name: string, type: StorageType): StorageUnit {
        const units = this.storageCollection[type];
        let unit = units[name];
        if (!unit) {
            unit = new StorageUnitImpl(name, type, this.destroyed$);
            units[name] = unit;
        }
        return unit;
    }
}

class StorageUnitImpl implements StorageUnit {

    private readonly storage: SimpleStorage;
    private readonly subscriptions: Record<string, BehaviorSubject<StorageItemChange>> = {};


    constructor(private readonly name: string, type: StorageType, destroyed$: Subject<boolean>) {
        switch (type) {
            case 'memory':
                this.storage = new MemoryStorage();
                break;
            case 'persistent':
                this.storage = localStorage;
                break;
            case 'session':
                this.storage = sessionStorage;
                break;
        }
        destroyed$.subscribe(destroyed => {
            if (destroyed) {
                Object.keys(this.subscriptions).forEach(key => this.subscriptions[key].complete());
            }
        });
    }


    /** Gets a value once. */
    getItem(key: string): Observable<string | null> {
        return this.monitorItem(key).pipe(take(1), map(change => change.newValue));
    }

    /** Monitors the value. You will keep receiving changes in your subscribtion until you unsubscribe. */
    monitorItem(key: string): Observable<StorageItemChange> {
        return this.getOrCreateSubscription(key).asObservable();
    }

    /** Set a value. */
    setItem(key: string, item: string): void {
        const fullKey = this.createFullKey(key);
        const oldValue = this.storage.getItem(key);
        this.storage.setItem(fullKey, item);
        this.notifyChange({ key: key, oldValue: oldValue, newValue: item });
    }

    /** Remove a value */
    removeItem(key: string): void {
        const fullKey = this.createFullKey(key);
        const oldValue = this.storage.getItem(fullKey);
        this.storage.removeItem(fullKey);
        this.notifyChange({ key: key, oldValue: oldValue, newValue: null });
    }


    private notifyChange(change: StorageItemChange) {
        this.getOrCreateSubscription(change.key).next(change);
    }

    private getOrCreateSubscription(key: string): BehaviorSubject<StorageItemChange> {
        let subscription = this.subscriptions[key];
        if (!subscription) {
            const fullKey = this.createFullKey(key);
            const item = this.storage.getItem(fullKey);
            const change: StorageItemChange = {
                key: key,
                newValue: item,
                oldValue: null
            };
            subscription = new BehaviorSubject<StorageItemChange>(change);
            this.subscriptions[key] = subscription;
        }
        return subscription;
    }

    private createFullKey(key: string) {
        return `___${this.name}.${key}`;
    }
}

interface SimpleStorage {
    clear(): void;
    getItem(key: string): string | null;
    removeItem(key: string): void;
    setItem(key: string, value: string): void;
}

class MemoryStorage implements SimpleStorage {

    private store: Record<string, string> = {};


    clear(): void {
        this.store = {};
    }

    getItem(key: string): string | null {
        if (key in this.store) {
            return this.store[key];
        }
        return null;
    }

    removeItem(key: string): void {
        delete this.store[key];
    }

    setItem(key: string, value: string): void {
        if (value == null) {
            return this.removeItem(key);
        }
        this.store[key] = value;
    }
}
