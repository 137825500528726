class ArrayHelper {

    getNext<T>(array: T[], index: number): T {
        if (array.length < 2) {
            throw new Error('no item is a valid next item');
        }
        const maxIndex = array.length - 1;
        if (index < maxIndex) {
            return array[index + 1];
        }
        return array[0];
    }

    getPrevious<T>(array: T[], index: number): T {
        if (array.length < 2) {
            throw new Error('no item is a valid previous item');
        }
        if (index > 0) {
            return array[index - 1];
        }
        return array[array.length - 1];
    }

    /**
     * Gets the distance of one element to another element considering a rotating array.
     * @param array 
     * @param sourceElement 
     * @param targetElement 
     */
    getMinimumDistance<T>(array: T[], sourceElement: T, targetElement: T) {
        // Consider array: ['a', 'b', 'c', 'd', 'e']
        // The distance of 'a' to 'a' is zero (0). 'a' moves zero right (or left)
        // The distance of 'a' to 'b' is one (1). 'a' moves one to the right and four to the left, so 1 is the minimum.
        // The distance of 'a' to 'c' is two (2). 'a' moves two to the right and three to the left, so 2 is the minimum.
        // The distance of 'a' to 'd' is two (2) 'a' moves three to the right and two to the left, so 2 is the minimum.
        // The distance of 'a' to 'e' is one (1) 'a' moves four to the right and one to the left, so 1 is the minimum.
        const indexOfSource = array.indexOf(sourceElement);
        const indexOfTarget = array.indexOf(targetElement);
        if (indexOfSource === indexOfTarget) {
            return 0;
        }
        const one = Math.abs(indexOfSource - indexOfTarget);
        const two = Math.abs((indexOfSource + array.length) - indexOfTarget);
        const three = Math.abs((indexOfSource) - (indexOfTarget + array.length));
        return Math.min(one, two, three);
    }

    /**
     * Get all elements between two elements in a rotating array.
     * @param array 
     * @param sourceElement 
     * @param targetElement 
     * @param direction 
     */
    getElementsBetween<T>(array: T[], sourceElement: T, targetElement: T, direction: 'left' | 'right'): T[] {
        // Consider array: ['a', 'b', 'c', 'd', 'e']
        // using 'a' and 'c' with direction right results in ['b']
        // using 'a' and 'c' with direction left results in ['e', 'd']
        // using 'd' and 'c' with direction right results in ['e', 'a', 'b']
        // using 'd' and 'c' with direction left results in []

        let sourceIndex = array.indexOf(sourceElement);

        if (sourceIndex === -1) {
            throw new Error('source element does not exist in array.');
        }

        if (array.indexOf(targetElement) === -1) {
            throw new Error('target element does not exist in array.');
        }

        const elements: T[] = [];
        if (direction === 'left') {
            while (true) {
                const previous = this.getPrevious(array, sourceIndex);
                if (previous === targetElement) {
                    break;
                }
                elements.push(previous);
                if (sourceIndex === 0) {
                    sourceIndex = array.length;
                }
                sourceIndex--;
            }
        }
        else if (direction === 'right') {
            while (true) {
                const next = this.getNext(array, sourceIndex);
                if (next === targetElement) {
                    break;
                }
                elements.push(next);
                sourceIndex++;
                if (sourceIndex === array.length) {
                    sourceIndex = 0;
                }
            }
        }
        return elements;
    }

    getDistances<T>(array: T[], sourceElement: T, targetElement: T): { left: number, right: number } {
        const sourceIndex = array.indexOf(sourceElement);
        if (sourceIndex === -1) {
            throw new Error('source element not found in array.');
        }
        const targetIndex = array.indexOf(targetElement);
        if (targetIndex === -1) {
            throw new Error('target element not found in array.');
        }
        let left = 0;
        let tmpLeftIndex = targetIndex;
        while (true) {
            left++;
            const prev = this.getPrevious(array, tmpLeftIndex);
            if (prev === sourceElement) {
                break;
            }
            tmpLeftIndex--;
            if (tmpLeftIndex < 0) {
                tmpLeftIndex = array.length - 1;
            }
        }
        let right = 0;
        let tmpRightIndex = targetIndex;
        while (true) {
            right++;
            const prev = this.getNext(array, tmpRightIndex);
            if (prev === sourceElement) {
                break;
            }
            tmpRightIndex++;
            if (tmpRightIndex > array.length - 1) {
                tmpRightIndex = 0;
            }
        }
        return { left, right };
    }
}

export const arrayHelper = new ArrayHelper();
