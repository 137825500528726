import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '@gallagher/configuration/app.config';
import { Page } from '../models/page';
import { ApplicantConfiguration } from './models/applicant-configuration';

@Injectable({
    providedIn: 'root'
})
export class ApplicantConfigurationService {

    private readonly url = appConfig.apiUrl + '/application/applicant-configurations';


    constructor(private http: HttpClient) { }


    getApplicantConfigurations(code: string | null = null) {
        const url = this.url;

        let params = new HttpParams();
        if (code) {
            params = params.set('code', code);
        }

        return this.http.get<Page<ApplicantConfiguration>>(url, { params });
    }

}
