import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';


@Injectable()
export class AppCustomPreloader implements PreloadingStrategy {
    preload(route: Route, fn: () => Observable<unknown>): Observable<unknown> {
        return route.data && route.data.preload ? fn() : of(null as unknown);
    }
}
