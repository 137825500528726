import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { GeocoderRequest } from './models/geocoder-request';
import { GeocoderResult } from './models/geocoder-result';

@Injectable()
export class GeocoderService {

    private readonly geoCoder = new google.maps.Geocoder();


    constructor() { }


    getGeocodes(request: GeocoderRequest): Observable<GeocoderResult[]> {
        const subject = new Subject<GeocoderResult[]>();
        void this.geoCoder.geocode(request, result => {
            if (result) {
                subject.next(result);
            }
            subject.complete();
        });
        return subject.asObservable();
    }

    getCurrentLocation(): Observable<google.maps.LatLng> {
        if (!navigator?.geolocation?.getCurrentPosition) {
            return throwError(new Error('location not supported'));
        }
        const subject = new ReplaySubject<google.maps.LatLng>(1);
        setTimeout(() => {
            navigator.geolocation.getCurrentPosition(position => {
                try {
                    const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    subject.next(latLng);
                    subject.complete();
                }
                catch (error) {
                    subject.error(error);
                }
            }, error => subject.error(error));
        }, 0);
        return subject.asObservable();
    }
}
