import { ErrorHandler } from '@angular/core';
import { ElasticApmService } from './elastic-apm.service';

export class ApmErrorHandler extends ErrorHandler {

    constructor(private readonly apmService: ElasticApmService) {
        super();
    }

    handleError(error: Error) {
        this.apmService.captureError(error);
        super.handleError(error);
    }
}