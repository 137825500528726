import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@gallagher/environments/environment';
import { L10nConfig } from '@gallagher/modules/l10n/configuration/l10n.config';
import { L10nDictionary } from '@gallagher/modules/l10n/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElasticApmConfig } from '@gallagher/modules/elastic-apm/elastic-apm-config';

class AppConfig {

    constructor() {
        this.createL10nConfig();
        this.createApmConfig();
    }


    l10nConfig: L10nConfig;
    apmConfig: ElasticApmConfig;
    apiUrl = environment.apiUrl;
    ignoredAuthUrls = environment.ignoredAuthUrls;


    private createL10nConfig() {
        const params = new URLSearchParams(document.location.search);
        const culture = params.get('locale') || localStorage.getItem('__culture') || 'nl';
        this.l10nConfig = {
            initialCulture: culture,
            loadLanguageData: (http, cltr) => this.loadLanguageData(http, cltr)
        };
    }

    private createApmConfig() {
        const envName = this.getEnvironmentName();
        this.apmConfig = {
            serverUrl: environment.elasticApmUrl,
            environment: envName,
            serviceName: 'Fence Configurator App'
        };
    }

    private getEnvironmentName() {
        if (environment.production) {
            return 'Production';
        }

        if (environment.integration) {
            return 'Integration';
        }

        if (environment.test) {
            return 'Test';
        }

        return 'Development';
    }

    private loadLanguageData(http: HttpClient, culture: string): Observable<L10nDictionary<string>> {
        const url = `${environment.apiUrl}/l10n/translations`;
        const headers = new HttpHeaders().append('Accept-Language', culture);
        return http.get<L10nTranslationModel[]>(url, { headers }).pipe(map(models => {
            localStorage.setItem('__culture', culture);
            return this.convertTranslationModels(models);
        }));
    }

    private convertTranslationModels(models: L10nTranslationModel[]): L10nDictionary<string> {
        const dictionary: L10nDictionary<string> = {};
        models.forEach(model => {
            dictionary[model.key] = model.text;
        });
        return dictionary;
    }
}

export const appConfig = new AppConfig();

interface L10nTranslationModel {

    key: string;
    text: string;

}
