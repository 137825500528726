/* eslint-disable @typescript-eslint/naming-convention */
export class Line {

    private _length: number;
    private _heading: number;


    constructor(
        public readonly from: google.maps.LatLng,
        public readonly to: google.maps.LatLng) {
    }


    get length(): number {
        if (!this._length) {
            this._length = google.maps.geometry.spherical.computeDistanceBetween(this.from, this.to);
        }
        return this._length;
    }

    get heading() {
        if (!this._heading) {
            this._heading = google.maps.geometry.spherical.computeHeading(this.from, this.to);
        }
        return this._heading;
    }


    reverse(): Line {
        return new Line(this.from, this.to);
    }

    equals(other: Line): boolean {
        return this.from.equals(other.from) && this.to.equals(other.to);
    }
}
