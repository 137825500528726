import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { ApmBase, init as initApm } from '@elastic/apm-rum';
import { ElasticApmConfig } from './elastic-apm-config';
import { ElasticApmService } from './elastic-apm.service';
import { ApmErrorHandler } from './error-handler';
import { Router } from '@angular/router';
import { environment } from '@gallagher/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createService(apm: ApmBase) {
    return (router: Router) => {
        return new ElasticApmService(router, apm);
    };
}

@NgModule({
})
export class ElasticApmModule {
    constructor(private readonly apmService: ElasticApmService) {}

    static forRoot(config: ElasticApmConfig): ModuleWithProviders<ElasticApmModule> {
        const apm = initApm({
            serviceName: config.serviceName,
            serverUrl: config.serverUrl,
            environment: config.environment,
            serviceVersion: '',
            logLevel: 'info',
            active: !environment.development
        });

        return {
            ngModule: ElasticApmModule,
            providers: [
                {
                    provide: ElasticApmService,
                    useFactory: createService(apm),
                    deps: [Router]
                },
                {
                    provide: ErrorHandler,
                    useClass: ApmErrorHandler,
                    deps: [
                        ElasticApmService
                    ]
                }
            ]
        };
    }
}
