import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslationService } from '../../../modules/l10n/services/translation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {

    private destroyed$ = new Subject<void>();


    constructor(private readonly translationService: TranslationService) {
        super();
        this.update();
        translationService.change$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.update());
    }


    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }


    private update() {
        super.itemsPerPageLabel = this.translationService.translate('Paginator.ItemsPerPageLabel');
        super.nextPageLabel = this.translationService.translate('Paginator.NextPageLabel');
        super.previousPageLabel = this.translationService.translate('Paginator.PreviousPageLabel');
        super.firstPageLabel = this.translationService.translate('Paginator.FirstPageLabel');
        super.lastPageLabel = this.translationService.translate('Paginator.LastPageLabel');
        super.getRangeLabel = (page, pageSize, length) => {
            page = (page + 1) | 0;
            const total = length | 0;
            let totalPages = 0;
            if (total && pageSize) {
                totalPages = Math.ceil(total / pageSize);
            }
            if (totalPages === 0) {
                page = 0;
            }
            return this.translationService.translate('Paginator.RangeLabel', { page, totalPages, pageSize, total });
        };
        this.changes.next(void 1);
    }
}