export class AcceptLanguageBuilder {

    private readonly languages: string[] = [];


    addLanguages(languages: Iterable<string>): AcceptLanguageBuilder {
        for (const lang of languages) {
            this.addLanguage(lang);
        }
        return this;
    }

    addLanguage(language: string): AcceptLanguageBuilder {
        this.languages.push(language);
        return this;
    }


    toString() {
        const results: string[] = [];
        this.languages.forEach((lang, i) => {
            let result: string;
            if (i === 0) {
                result = lang;
            }
            else {
                const weight = this.getWeight(i);
                result = `${lang};q=${weight}`;
            }
            results.push(result);
        });
        const output = results.join(', ');

        return output;
    }


    private getWeight(index: number): number {
        if (this.languages.length < 2) {
            return 1;
        }
        const maxIndex = this.languages.length - 1;
        const priority = maxIndex - index;
        const weight = maxIndex / priority;
        const percentage = 1 / weight;
        return percentage;
    }
}
