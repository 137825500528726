import { environment } from '@gallagher/environments/environment';

class BlobStorageUrlFormatter {

    getProductImageUrl(productId: string) {
        const baseUrl = this.getBaseUrl();
        return baseUrl + 'fence-configurator/resources/images/Icon-' + productId + '.jpg';
    }

    private getBaseUrl() {
        if (environment.blobStorageUrl && !environment.blobStorageUrl.endsWith('/')) {
            return environment.blobStorageUrl + '/';
        }
        return environment.blobStorageUrl;
    }
}

export const blobStorageUrlFormatter = new BlobStorageUrlFormatter();