import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AcceptLanguageBuilder } from '@gallagher/core/helpers';
import { environment } from '@gallagher/environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { L10nConfig } from '../configuration/l10n.config';
import { L10nConfigToken } from '../internal/tokens';
import { L10nDictionary } from '../types';

const interpolatioRegex = /{+(.*?)}+/g;


@Injectable()
export class TranslationService {

    private selectedLanguageDictionary: L10nDictionary<string> = {};
    private cache: { [language: string]: L10nDictionary<string> } = {};
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _currentCulture: string;
    private changeSubject = new Subject<string>();


    constructor(@Inject(L10nConfigToken) private readonly config: L10nConfig, private readonly http: HttpClient) {
        this.change$ = this.changeSubject.asObservable();
    }


    change$: Observable<string>;
    get currentCulture(): string {
        return this._currentCulture;
    }


    translate(key: string, arg?: L10nDictionary | object) {
        let input = this.selectedLanguageDictionary[key];

        if (typeof input === 'undefined') {
            if (!environment.production) {
                // eslint-disable-next-line no-console
                console.error(`Translation not found for key: ${key}`);
            }
            return key;
        }

        if (input) {
            input = this.formatString(input, arg);
        }

        return input;
    }

    loadLanguage(culture: string, omitCache = false) {
        const language = culture.split('-')[0].toLowerCase();
        if (!omitCache && language in this.cache) {
            this.selectedLanguageDictionary = this.cache[language];
            return of(null as unknown as void);
        }
        return this.config.loadLanguageData(this.http, culture).pipe(catchError(error => this.handleLoadError(error)), map(data => {
            this.selectedLanguageDictionary = this.cache[language] = data;
            this._currentCulture = culture;
            this.changeSubject.next(culture);
            return void 1;
        }));
    }

    reload() {
        this.cache = {};
        return this.loadLanguage(this._currentCulture, true);
    }

    updateTranslations(dictionary: L10nDictionary<string>) {
        this.selectedLanguageDictionary = dictionary;
    }

    getAcceptLanguage(): string {
        const builder = new AcceptLanguageBuilder();
        if (this._currentCulture) {
            builder.addLanguage(this._currentCulture);
        }
        if (navigator.languages) {
            builder.addLanguages(navigator.languages);
        }
        const acceptLanguage = builder.toString().trim();
        if (acceptLanguage) {
            return acceptLanguage;
        }
        return 'nl-NL';
    }
    

    private handleLoadError(error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
        return of({} as L10nDictionary<string>);
    }

    private formatString(template: string, arg?: L10nDictionary | object) {
        if (arg == null) {
            arg = {};
        }
        const obj = arg as L10nDictionary;
        return template.replace(interpolatioRegex, (match, key: string) => {
            const escape = /{{(.*)}}/.exec(match);
            if (escape) {
                const escapeVal = escape[1];
                if (typeof escapeVal === 'undefined') {
                    return match;
                }
                return escapeVal;
            }
            const value = obj[key];
            if (typeof value === 'undefined') {
                return match;
            }
            if (value === null) {
                return '';
            }
            return String(value);
        });
    }
}
