import { Intersection } from './intersection';
import { Line } from './line';

export class PolygonSlice {

    constructor(
        public readonly path: google.maps.LatLng[],
        public readonly lines: Line[],
        public readonly intersections: Intersection[]) {
    }

}
