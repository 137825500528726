import { Injectable } from '@angular/core';
import { enumerable } from '@cological/linq';
import { Enumerable } from '@cological/linq/core/types/enumerable';
import { throwError } from 'rxjs';
import { RotatingIterator } from '../helpers';
import { Intersection } from './models/intersection';
import { Line } from './models/line';
import { PolygonSlice } from './models/polygon-slice';


@Injectable({
    providedIn: 'root'
})
export class GeometryService {

    constructor() { }


    getPolygonSlices(lines: Line[], percentage: number): PolygonSlice[] {
        const slices = enumerable(lines)
            .select(line => this.getPolygonSlice(lines, line, percentage))
            .where(slice => !!slice)
            .select(slice => slice!)
            .toArray();
        return slices;
    }

    getPolygonSlice(lines: Line[], referenceLine: Line, percentage: number, maxDeviationPercentage = .1): PolygonSlice | null {
        if (percentage > 1) {
            throw new Error('percentage must be between 0 and 1');
        }
        if (lines.length < 2) {
            throw throwError(new Error('invalid polygon'));
        }
        const path = this.convertToPath(lines);
        const polygon = new google.maps.Polygon();
        polygon.setPaths([path]);
        const isClockwise = this.isPathClockwise(path);
        const lastLine = lines[lines.length - 1];
        const firstLine = lines[0];

        // Check if the lines close the polygon, otherwise add the last line.
        if (!lastLine.to.equals(firstLine.from)) {
            lines.push(new Line(lines[lines.length - 1].to, lines[0].from));
        }

        const enumerableLines = enumerable(lines);
        const totalArea = this.getArea(path);
        const areaSizeWeWant = totalArea * percentage;
        const heading = this.getPerpendicularInwardHeading(referenceLine, polygon);
        const stretchedLine = this.stretchLine(referenceLine, 10000);
        const moveIncrement = 0.1;
        let move = moveIncrement;
        let area = 0;
        let foundArea = totalArea;
        let foundSlice: PolygonSlice | null = null;
        let intersections: Intersection[] = [];
        let maxIterationCount = 100000;
        while (maxIterationCount > 0) {
            maxIterationCount--;
            const intersectorLine = this.moveLine(stretchedLine, move, heading);
            intersections = this.getIntersections(enumerableLines, intersectorLine);
            if (!isClockwise) {
                intersections = intersections.reverse();
            }
            if (intersections.length > 1) {
                intersections = enumerable(intersections).orderBy(is => lines.indexOf(is.line)).toArray();
                const newSlice = this.createPathSlice(lines, referenceLine, intersections, isClockwise);
                area = this.getArea(newSlice.path);
                if (area > areaSizeWeWant) {
                    const diff = area - areaSizeWeWant;
                    const previousDiff = Math.abs(areaSizeWeWant - foundArea);
                    if (diff < previousDiff) {
                        foundSlice = newSlice;
                        foundArea = area;
                    }
                    break;
                }
                foundArea = area;
                foundSlice = newSlice;
            }
            move += moveIncrement;
        }

        if (foundSlice == null) {
            return null;
        }

        const deviation = Math.abs(areaSizeWeWant - foundArea);
        const maxDeviation = maxDeviationPercentage / 100 * areaSizeWeWant;
        if (deviation > maxDeviation) {
            return null;
        }

        return foundSlice;
    }

    createLines(path: google.maps.LatLng[]): Line[];
    createLines(path: google.maps.LatLng[], closePath: boolean): Line[];
    createLines(path: Enumerable<google.maps.LatLng>): Line[]
    createLines(path: Enumerable<google.maps.LatLng>, closePath: boolean): Line[]
    createLines(path: google.maps.LatLng[] | Enumerable<google.maps.LatLng>, closePath = true): Line[] {
        if (Array.isArray(path)) {
            path = enumerable(path);
        }
        const lines: Line[] = [];
        let previous: google.maps.LatLng | null = null;

        for (const location of path) {
            if (previous) {
                const line = new Line(previous, location);
                lines.push(line);
            }

            previous = location;
        }

        if (lines.length > 1 && closePath) {
            const lastLine = lines[lines.length - 1];
            const firstLine = lines[0];

            // Check if the lines close the polygon, otherwise add the last line.
            if (!lastLine.to.equals(firstLine.from)) {
                lines.push(new Line(lines[lines.length - 1].to, lines[0].from));
            }
        }

        return lines;
    }

    createClockwisePath(path: google.maps.LatLng[]) {
        path = path.slice();
        if (!this.isPathClockwise(path)) {
            path.reverse();
        }
        return path;
    }

    createCounterClockwisePath(path: google.maps.LatLng[]) {
        path = path.slice();
        if (this.isPathClockwise(path)) {
            path.reverse();
        }
        return path;
    }

    getArea(path: google.maps.LatLng[]) {
        return google.maps.geometry.spherical.computeArea(path);
    }

    isPathClockwise(path: google.maps.LatLng[]) {
        const values: number[] = [];
        for (let i = 0; i < path.length - 1; i++) {
            const current = path[i];
            const next = path[i + 1];
            const value = this.getRotationSum(current, next);
            values.push(value);
        }
        const last = path[path.length - 1];
        const first = path[0];
        const value = this.getRotationSum(last, first);
        values.push(value);
        const sum = enumerable(values).sum(v => v);
        return sum < 0;
    }

    getPerpendicularLine(line: Line): Line {
        const newHeading = this.rotateHeading(line.heading, 90);
        const center = this.getCenter(line);
        const x = google.maps.geometry.spherical.computeOffset(center, line.length / 2, newHeading);
        const y = google.maps.geometry.spherical.computeOffset(center, line.length / 2, this.invertHeading(newHeading));
        const perpendicularLine = new Line(x, y);

        return perpendicularLine;
    }

    getPerpendicularInwardHeading(line: Line, polygon: google.maps.Polygon) {
        let heading = this.rotateHeading(line.heading, 90);
        let center = this.getCenter(line);
        center = google.maps.geometry.spherical.computeOffset(center, .1, heading);
        const isInsidePolygon = google.maps.geometry.poly.containsLocation(center, polygon);
        if (!isInsidePolygon) {
            heading = this.invertHeading(heading);
        }
        return heading;
    }

    getPerpendicularOutwardHeading(line: Line, polygon: google.maps.Polygon) {
        let heading = this.rotateHeading(line.heading, 90);
        let center = this.getCenter(line);
        center = google.maps.geometry.spherical.computeOffset(center, .1, heading);
        const isInsidePolygon = google.maps.geometry.poly.containsLocation(center, polygon);
        if (isInsidePolygon) {
            heading = this.invertHeading(heading);
        }
        return heading;
    }

    rotateHeading(heading: number, degrees: number): number {
        degrees = degrees < 0 ? -(degrees % 360) : (degrees % 360);
        heading = heading + degrees;
        if (heading < -180) {
            heading += 360;
        }
        else if (heading > 180) {
            heading -= 360;
        }
        return heading;
    }

    stretchLine(line: Line, distance: number) {
        const d = distance / 2;
        const from = google.maps.geometry.spherical.computeOffset(line.from, d, this.invertHeading(line.heading));
        const to = google.maps.geometry.spherical.computeOffset(line.to, d, line.heading);
        return new Line(from, to);
    }

    getCenter(line: Line): google.maps.LatLng {
        return google.maps.geometry.spherical.interpolate(line.from, line.to, 0.5);
    }

    moveLine(line: Line, distance: number, heading: number): Line {
        const from = google.maps.geometry.spherical.computeOffset(line.from, distance, heading);
        const to = google.maps.geometry.spherical.computeOffset(line.to, distance, heading);
        return new Line(from, to);
    }

    invertHeading(heading: number) {
        return this.rotateHeading(heading, 180);
    }

    getIntersections(lines: Line[], line: Line): Intersection[];
    getIntersections(lines: Enumerable<Line>, line: Line): Intersection[];
    getIntersections(lines: Enumerable<Line> | Line[], line: Line): Intersection[] {
        if (Array.isArray(lines)) {
            lines = enumerable(lines);
        }
        const intersectionDataCollection =
            lines
                .select(ln => ({ location: this.getIntersection(ln, line), line: ln }))
                .where(is => is.location != null)
                .select(is => new Intersection(is.location!, is.line))
                .toArray();
        return intersectionDataCollection;
    }

    getIntersection(line1: Line, line2: Line): google.maps.LatLng | null {
        const x1 = line1.from.lat();
        const x2 = line1.to.lat();
        const y1 = line1.from.lng();
        const y2 = line1.to.lng();
        const x3 = line2.from.lat();
        const x4 = line2.to.lat();
        const y3 = line2.from.lng();
        const y4 = line2.to.lng();

        // Check if none of the lines are of length 0
        if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
            return null;
        }

        const denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));

        // Lines are parallel
        if (denominator === 0) {
            return null;
        }

        const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
        const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

        // is the intersection along the segments
        if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
            return null;
        }

        // Return a object with the x and y coordinates of the intersection
        const x = x1 + ua * (x2 - x1);
        const y = y1 + ua * (y2 - y1);

        return new google.maps.LatLng(x, y);
    }

    createPathSlice(lines: Line[], startingLine: Line, intersections: Intersection[], isClockwise: boolean): PolygonSlice {
        const indexOfStartingLine = lines.indexOf(startingLine);
        if (indexOfStartingLine === -1) {
            throw new Error('startingLine must be part of the lines.');
        }
        const rotatingIterator = new RotatingIterator(lines, indexOfStartingLine);
        const path: google.maps.LatLng[] = [];
        let add = true;
        const step = isClockwise ? 1 : -1;
        const newLines: Line[] = [];
        while (true) {
            const next = rotatingIterator.next(step);
            if (add) {
                if (isClockwise) {
                    path.push(next.from);
                }
                else {
                    path.push(next.to);
                }
            }
            if (next === startingLine) {
                break;
            }
            const intersecting = intersections.find(is => is.line === next);
            if (intersecting) {
                if (!add) {
                    newLines.push(new Line(path[path.length - 1], intersecting.location));
                }
                add = !add;
                path.push(intersecting.location);
            }
        }

        const slice = new PolygonSlice(path, newLines, intersections);

        return slice;
    }


    private convertToPath(lines: Line[]) {
        const path: google.maps.LatLng[] = [];
        lines.forEach(l => path.push(l.from));
        if (path.length > 1) {
            const lastLine = lines[lines.length - 1];
            const firstPoint = path[0];
            if (!firstPoint.equals(lastLine.to)) {
                path.push(lastLine.to);
            }
        }
        return path;
    }

    private getRotationSum(current: google.maps.LatLng, next: google.maps.LatLng) {
        const currentX = current.lat();
        const currentY = current.lng();
        const nextX = next.lat();
        const nextY = next.lng();
        const x = nextX - currentX;
        const y = nextY + currentY;
        const value = x * y;

        return value;
    }
}
