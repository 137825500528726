import { IEnvironment } from './IEnvironment';

export const environment : IEnvironment = {
    development: false,
    test: true,
    integration: false,
    production: false,
    apiUrl: 'https://fenceconfigurator.rovecomtest.nl/api',
    elasticApmUrl: 'https://4021cc8c695448eab0f579adfa54ea7c.apm.westeurope.azure.elastic-cloud.com:443',
    ignoredAuthUrls: ['https://fenceconfigurator.rovecomtest.nl/api/auth'],
    blobStorageUrl: 'https://srovecomweustorage.blob.core.windows.net/om-gallagher-test-public'
};
