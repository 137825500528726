import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivationStart, Router } from '@angular/router';
import { DefaultHttpInterceptor } from '@gallagher/interceptors/default-http.interceptor';
import { ElasticApmModule, L10nModule, MaterialModule, ToolsModule } from '@gallagher/modules';
import { TranslationService } from '@gallagher/modules/l10n/services/translation.service';
import { AuthService, StorageFactoryService } from '@gallagher/services';
import { ApplicantConfigurationService } from '@gallagher/services/application';
import { forkJoin, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appConfig } from './configuration/app.config';
import { CustomMatPaginatorIntl } from './core/angular/intl/custom-mat-paginator-intl';
import { LayoutComponent } from './layouts/layout/layout.component';
import { StorageUnit } from './services/models/storage-unit';


@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ElasticApmModule.forRoot(appConfig.apmConfig),
        L10nModule.forRoot(appConfig.l10nConfig),
        MaterialModule,
        ToolsModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultHttpInterceptor, multi: true },
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl, deps: [TranslationService] }
    ],
    bootstrap: [],
    entryComponents: [AppComponent]
})
export class AppModule implements DoBootstrap {

    private readonly storage: StorageUnit;

    constructor(
        private readonly authService: AuthService,
        private readonly applicantConfigService: ApplicantConfigurationService,
        private readonly translationService: TranslationService,
        private readonly router: Router,
        private readonly titleService: Title,
        storageFactoryService: StorageFactoryService) {
        this.storage = storageFactoryService.createStorage('webshop', 'persistent');
    }


    ngDoBootstrap(appRef: ApplicationRef): void {
        const url = new URL(document.location.href);
        const applicantConfigCode = url.searchParams.get('code');
        const culture = url.searchParams.get('culture') || 'nl-NL';
        const bootTasks: Observable<void>[] = [
            this.authService.updateLoginState().pipe(map(() => void 1))
        ];

        if (culture) {
            bootTasks.push(this.translationService.loadLanguage(culture));
        }

        if (applicantConfigCode) {
            bootTasks.push(this.applicantConfigService.getApplicantConfigurations(applicantConfigCode).pipe(map(page => {
                if (page.items.length === 1) {
                    const item = page.items[0];
                    this.storage.setItem('webshop-code', item.code);
                    this.storage.setItem('webshop-redirectUrl', item.redirectUri);
                    this.storage.setItem('webshop-culture', culture);
                }
                return void 1;
            })));
        }

        forkJoin(bootTasks).subscribe(() => {
            appRef.bootstrap(AppComponent);
            this.router.events.pipe(filter(e => e instanceof ActivationStart)).subscribe((e: ActivationStart) => {
                const titleKey = e.snapshot.data?.title as string | null;
                let title: string;
                if (titleKey) {
                    title = 'Gallagher - ' + this.translationService.translate(titleKey);
                }
                else {
                    title = 'Gallagher';
                }
                this.titleService.setTitle(title);
            });
        });
    }
}
