import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '@gallagher/configuration/app.config';
import { AuthService } from '@gallagher/services';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {

    constructor(private readonly authService: AuthService) {
    }


    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.isIgnoredUrl(req.url)) {
            return next.handle(req);
        }
        return this.authService.getToken().pipe(catchError(() => of(null)), mergeMap(token => {
            if (token?.access_token) {
                const typ = token.token_type || 'Bearer';
                const header = `${typ} ${token.access_token}`;
                req = req.clone({ headers: req.headers.set('Authorization', header) });
            }
            return next.handle(req);
        }));
    }


    private isIgnoredUrl(url: string) {
        if (!url || appConfig.ignoredAuthUrls == null || appConfig.ignoredAuthUrls.length === 0) {
            return false;
        }
        const normalized = url.toUpperCase();
        for (let i = 0; i < appConfig.ignoredAuthUrls.length; i++) {
            const ignoredUrl = appConfig.ignoredAuthUrls[i];
            if (normalized.startsWith(ignoredUrl.toUpperCase())) { 
                return true;
            }
        }
        return false;
    }
}