import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { L10nModule } from '../l10n/l10n.module';
import { MaterialModule } from '../material/material.module';
import { MapDrawingComponent } from './components/map-drawing/map-drawing.component';
import { SegmentCountDialogComponent } from './components/map-drawing/segment-count-dialog/segment-count-dialog.component';
import { GeocoderService } from './services/geocoder.service';
import { SearchInputComponent } from './components/map-drawing/search-input/search-input.component';
import { PassagesDialogComponent } from './components/map-drawing/passages-dialog/passages-dialog.component';
import { ShapeService } from './services/shape.service';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        GoogleMapsModule,
        L10nModule,
        MaterialModule
    ],
    declarations: [
        MapDrawingComponent,
        PassagesDialogComponent,
        SegmentCountDialogComponent,        
        SearchInputComponent
    ],
    exports: [
        MapDrawingComponent
    ],
    providers: [
        GeocoderService,
        ShapeService
    ]
})
export class DrawingModule { }
