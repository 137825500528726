import { Injectable } from '@angular/core';
import { enumerable } from '@cological/linq';
import { GeometryService } from '@gallagher/core/math';
import { Polygon, Polyline } from '../components/map-drawing/shapes';
import { PolyDistanceInfo } from './models';

@Injectable()
export class ShapeService {

    constructor(private readonly geometryService: GeometryService) {
    }


    getNearestShapeInfo(latLng: google.maps.LatLng, shapes: Iterable<Polygon | Polyline>): PolyDistanceInfo | null {
        const infos: PolyDistanceInfo[] = [];
        for (const shape of shapes) {
            const info = this.getPolyDistanceInfo(latLng, shape);
            infos.push(info);
        }

        const bestMatch = enumerable(infos).orderBy(i => i.distance).firstOrNull();
        return bestMatch;
    }


    private getPolyDistanceInfo(latLng: google.maps.LatLng, polygonOrPolyline: Polygon | Polyline): PolyDistanceInfo {
        const lines = this.geometryService.createLines(polygonOrPolyline.gmShape.getPath().getArray());
        const nearestPointsOnLines = lines.map(line => {
            const closestPoint = this.getClosestPointToLine(latLng, { from: line.from, to: line.to });
            return {
                distance: closestPoint.distance,
                latLng: closestPoint.latLng,
                line
            };
        });
        const nearest = enumerable(nearestPointsOnLines).orderBy(point => point.distance).first();
        const distanceFromFrom = google.maps.geometry.spherical.computeDistanceBetween(nearest.line.from, nearest.latLng);
        const distanceFromTo = google.maps.geometry.spherical.computeDistanceBetween(nearest.line.to, nearest.latLng);
        const distanceFromNearestCorner = distanceFromFrom > distanceFromTo ? distanceFromTo : distanceFromFrom;
        const nearestCorner = distanceFromFrom > distanceFromTo ? nearest.line.to : nearest.line.from;
        const info: PolyDistanceInfo = {
            distance: nearest.distance,
            nearestLatLng: nearest.latLng,
            nearestLine: nearest.line,
            distanceFromNearestCorner,
            nearestCorner: nearestCorner,
            poly: polygonOrPolyline
        };

        return info;
    }

    private getClosestPointToLine(point: google.maps.LatLng, line: { from: google.maps.LatLng, to: google.maps.LatLng }): { latLng: google.maps.LatLng, distance: number } {

        // Found on Stackoverflow and converted to typescript: https://stackoverflow.com/a/3122532
        
        const lineFromToPoint = [point.lat() - line.from.lat(), point.lng() - line.from.lng()]; // Storing vector from -> point
        const lineFromToLineTo = [line.to.lat() - line.from.lat(), line.to.lng() - line.from.lng()]; // Storing vector from -> to

        const lxToLySquared = Math.pow(lineFromToLineTo[0], 2) + Math.pow(lineFromToLineTo[1], 2); // Finding the squared magnitude of lineFromToLineTo

        const atp_dot_atb = (lineFromToPoint[0] * lineFromToLineTo[0]) + (lineFromToPoint[1] * lineFromToLineTo[1]); // The dot product of lineFromToPoint and lineFromToLineTo

        const t = atp_dot_atb / lxToLySquared; // The normalized "distance" from a to your closest point

        const x = line.from.lat() + lineFromToLineTo[0] * t; // Add the distance to A, moving towards B
        const y = line.from.lng() + lineFromToLineTo[1] * t; // Add the distance to A, moving towards B

        const latLng = new google.maps.LatLng(x, y);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(point, latLng);
        return { latLng, distance };
    }
}


