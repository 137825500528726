import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumToKvpPipe } from './pipes/enum-to-kvp.pipe';
import { ImageDirective } from './directives/image.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        EnumToKvpPipe,
        ImageDirective
    ],
    exports: [
        EnumToKvpPipe,
        ImageDirective
    ]
})
export class ToolsModule { }
