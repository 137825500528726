import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ApmBase, Transaction } from '@elastic/apm-rum';


@Injectable()
export class ElasticApmService {

    constructor(private readonly router: Router, private readonly apm: ApmBase) {
        if (apm.isActive()) {
            this.observe();
        }
    }

    observe() {
        let transaction: Transaction | undefined;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const url = event.url;
                transaction = this.apm.startTransaction(url, 'route-change', {
                    managed: true,
                    canReuse: true
                });
            }
            else if (event instanceof NavigationError) {
                if (transaction && transaction.isFinished()) {
                    transaction.end();
                }
            }
            else if (event instanceof NavigationEnd) {
                if (!transaction) {
                    return;
                }

                /**
                 * The below logic must be placed in NavigationEnd since
                 * the we depend on the current route state to get the path
                 *
                 * Even If there are any redirects, the router state path
                 * will be matched with the correct url on navigation end
                 *
                 * Traverse the activated route tree to figure out the nested
                 * route path
                 */
                let child = this.router.routerState.root.firstChild;
                if (child) {
                    const paths: string[] = [];
                    if (child.routeConfig?.path) {
                        paths.push(child.routeConfig.path);
                    }
                    while (true) {
                        if (!child.firstChild) {
                            break;
                        }
                        child = child.firstChild;
                        if (child.routeConfig?.path) {
                            paths.push(child.routeConfig.path);
                        }
                    }
                    transaction.name = '/' + paths.join('/');
                }

                if (transaction.isFinished()) {
                    transaction.end();
                }
            }
        });
    }

    captureError(error: Error | string) {
        this.apm.captureError(error);
    }
}