import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/en-GB';
import '@angular/common/locales/global/nl';
import '@angular/common/locales/global/nl-BE';
import { APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { L10nComponent } from './components/l10n/l10n.component';
import { L10nConfig } from './configuration/l10n.config';
import { L10nDirective } from './directives/l10n.directive';
import { getMatchingBrowserCulture } from './internal/get-matching-browser-culture';
import { L10nConfigToken } from './internal/tokens';
import { L10nPipe } from './pipes/l10n.pipe';
import { TranslationService } from './services/translation.service';

const cultures = ['nl', 'nl-BE', 'en-GB'];

export function initialize(l10nConfig: L10nConfig, translationService: TranslationService) {
    return () => translationService.loadLanguage(l10nConfig.initialCulture).toPromise();
}

export function getLocale() {
    const key = '__culture';
    let id = localStorage.getItem(key);
    if (id && cultures.includes(id)) {
        return id;
    }
    id = getMatchingBrowserCulture(cultures) || cultures[0];
    localStorage.setItem(key, id);
    return id;
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [
        L10nDirective,
        L10nPipe,
        L10nComponent
    ],
    exports: [
        L10nDirective,
        L10nPipe,
        L10nComponent
    ]
})
export class L10nModule {

    static forRoot(config: L10nConfig): ModuleWithProviders<L10nModule> {
        return {
            ngModule: L10nModule,
            providers: [
                TranslationService,
                {
                    provide: L10nConfigToken,
                    useValue: config
                },
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    deps: [L10nConfigToken, TranslationService],
                    useFactory: initialize
                },
                {
                    provide: LOCALE_ID,
                    useValue: getLocale()
                },
            ]
        };
    }
}
